import { breakpoint } from '@/helpers/BreakpointHelper';
import styled from 'styled-components';

const MainTabButton = styled.button<{ active?: boolean }>`
  color: ${(props) => (props.active === true ? `#fff` : `#2d2926`)};
  background: ${(props) => (props.active === true ? `#1cabe2` : `#f8f9fa`)};
  font-size: 20px;
  border-radius: 28px;
  height: 56px;
  padding: 0 24px;
  border: none;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: -1.2px;
  transition: all 0.2s;
  &[disabled]:hover {
    color: ${(props) => (props.active === true ? `#fff` : `#2d2926`)};
    background: ${(props) => (props.active === true ? `#1cabe2` : `#f8f9fa`)};
  }
  // 모바일에서는 hover css 기능 제거
  @media (hover: hover) {
    &:hover {
      color: #fff;
      background: #1cabe2;
    }
  }

  ${breakpoint(800)} {
    font-size: 16px;
    padding: 0 16px;
  }
  ${breakpoint(640)} {
    height: 48px;
  }
`;

export default MainTabButton;
