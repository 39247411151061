import MainTabButton from './MainTabButton';
import { breakpoint } from '@/helpers/BreakpointHelper';
import React, { FC, useCallback, useState } from 'react';
import styled from 'styled-components';

interface TabProps {
  tabs: TabData[];
}
export interface TabData {
  id: number;
  subject: string;
  youtube: string;
}

const PanelContainer = styled.div`
  overflow-x: auto;
`;
const UiTabPanel = styled.ul`
  display: inline-flex;

  li {
    white-space: nowrap;
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
`;
const TabContainer = styled.div`
  width: 100%;
  margin-top: 32px;

  ${breakpoint(`tablet`)} {
    margin-top: 24px;
  }
`;

const UiTabPanelItem = styled.li`
  white-space: nowrap;
`;

const VideoContainer = styled.div`
  width: 100%;
  position: relative;

  .video-wrap {
    width: 100%;
    max-width: 1120px;
    margin: 0 auto;
    /* padding-top: 66.69%; */
    padding-top: 56.25%;
    position: relative;
  }
  .video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  ${breakpoint(`tablet`)} {
    &::before {
      width: auto;
      height: 0;
      padding-top: 69.44%;
      left: -20px;
      right: -20px;
      transform: translate(0, 0);
    }
  }
`;

export const TabPanel: FC<TabProps> = ({ tabs }) => {
  const [contentIndex, setContentIndex] = useState(0);

  const onButtonClick = useCallback((index: number) => {
    setContentIndex(index);
  }, []);

  return (
    <>
      <PanelContainer>
        <UiTabPanel className="ui-tabpanel-list">
          {tabs.map((row, index) => (
            <UiTabPanelItem key={row.id}>
              <MainTabButton
                active={contentIndex === index}
                type="button"
                key={row.id}
                onClick={() => onButtonClick(index)}
              >
                {row.subject}
              </MainTabButton>
            </UiTabPanelItem>
          ))}
        </UiTabPanel>
      </PanelContainer>
      <TabContainer>
        <VideoContainer>
          <div className="video-wrap">
            <iframe
              className="video"
              src={`https://www.youtube.com/embed/${tabs[contentIndex].youtube}`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </VideoContainer>
      </TabContainer>
    </>
  );
};

export default TabPanel;
