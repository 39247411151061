import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  border-top: 2px solid #000;
  border-left: 1px solid #e5e6e8;

  thead {
    th {
      font-weight: 700;
      text-align: center;
      padding: 14px 10px;
      border-bottom: 1px solid #000;
      border-right: 1px solid #e5e6e8;
    }
  }

  tbody {
    td {
      padding: 16px 20px;
      border-bottom: 1px solid #e5e6e8;
      border-right: 1px solid #e5e6e8;
    }

    tr:last-child {
      td {
        // border-color: #000;
      }
    }
  }
`;
