import { HonorsClubData } from './honors-club';
import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import { HonorsClubControllerService } from '@/__generated__/FrontApi';
import LegacyBn1 from '@/assets/img/tmp/legacy/legacy_bn1@2x.jpg';
import LegacyBn1M from '@/assets/img/tmp/legacy/legacy_bn1M@2x.jpg';
import LegacyBn2 from '@/assets/img/tmp/legacy/legacy_bn2@2x.jpg';
import LegacyBn2M from '@/assets/img/tmp/legacy/legacy_bn2M@2x.jpg';
import HowImg1 from '@/assets/img/tmp/legacy/legacy_card_img1@2x.png';
import HowImg2 from '@/assets/img/tmp/legacy/legacy_card_img2@2x.png';
import HowImg3 from '@/assets/img/tmp/legacy/legacy_card_img3@2x.png';
import HowObj1 from '@/assets/img/tmp/legacy/legacy_card_obj1@2x.png';
import HowObj2 from '@/assets/img/tmp/legacy/legacy_card_obj2@2x.png';
import HowObj3 from '@/assets/img/tmp/legacy/legacy_card_obj3@2x.png';
import AccordionComponent, { AccordionItem } from '@/components/Accordion';
import Button from '@/components/Button';
import ButtonMoreView from '@/components/ButtonMoreView';
import ContactUs from '@/components/ContactUs';
import Container from '@/components/Container';
import ErrorMessage from '@/components/Form/ErrorMessage';
import FormGroup from '@/components/Form/FormGroup';
import LabelText from '@/components/Form/LabelText';
import Image from '@/components/Image';
import InputCheckbox from '@/components/Input/InputCheckbox';
import InputNumber from '@/components/Input/InputNumber';
import InputText from '@/components/Input/InputText';
import LegacyLayerPopup from '@/components/LegacyLayerPopup';
import TabPanel, { TabData } from '@/components/LegacyTab';
import LinkSafe from '@/components/LinkSafe';
import { SectionTop } from '@/components/Nodiscrimination';
import PageComponent from '@/components/PageComponent';
import Section from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { SelectItemDefaultData } from '@/helpers/GtmHelper';
import { SUPPORT_TERM_ONCE } from '@/helpers/SupportHelper';
import { emailRegex, mobileRegex } from '@/helpers/ValidationHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { usePopupStore } from '@/stores/PopupStore';
import { Link, PageProps } from 'gatsby';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { GtmButtonDonate } from '@/components/Button.gtm';

const tab: TabData[] = [
  {
    id: 1,
    subject: `나눔의 기쁨 편`,
    youtube: `u48krgIx0B8`,
  },
  {
    id: 2,
    subject: `나비효과 편`,
    youtube: `SGSYiCaUmwg`,
  },
  {
    id: 3,
    subject: `정갑영 회장 편`,
    youtube: `_OCCwMA4ubs`,
  },
  {
    id: 4,
    subject: `인생기부 편`,
    youtube: `GcVjrgjgv7M`,
  },
  {
    id: 5,
    subject: `3일 노트 편`,
    youtube: `df-dsmOS_Fc`,
  },
];
const tab2: TabData[] = [
  {
    id: 1,
    subject: `추모편지 편`,
    youtube: `iJSyE6NHO80`,
  },
];
const howData = [
  {
    id: 1,
    obj: HowObj1,
    tit: `<strong>유언공정증서</strong>에 의한 유산기부`,
    dec: `기부자 (유언자)가 증인 2명이 참석한 가운데 공증인 앞에서 유언 내용을 말하고, 공증인이 이 같은 유언 내용을 공정증서에 기록하는 방법`,
    img: HowImg1,
    review: `"지금 살고있는 아늑한 내 집이 언젠가는 저 어린이들에게 도움이 되기를 바라며, '내 집을 유니세프에 기부한다'는 내용을 유언장에 적어 두었습니다."`,
    writer: `김일두 후원자`,
    link: `/`,
  },
  {
    id: 2,
    obj: HowObj2,
    tit: `<strong>신탁</strong>에 의한 유산기부`,
    dec: `유언자가 금융회사와 자산신탁계약을 맺고 자산관리를 위탁하면서 사망 후 자산의 전부나 일부를 받게 될 수익자를 유니세프로 지정하는 방법`,
    img: HowImg2,
    review: `2018년 유니세프한국위원회와 신영증권이 유산기부 활성화 업무협약을 맺은 이후 유언대용신탁을 활용한 유산기부 약정의 첫 후원자입니다.`,
    writer: `이서인 후원자`,
    link: `/`,
  },
  {
    id: 3,
    obj: HowObj3,
    tit: `<strong>보험</strong>에 의한 유산기부`,
    dec: `사망 보험금의 수익자를 유니세프로 지정하는 기부 보험에 가입하거나, 종신보험 수익자를 유니세프로 변경하여 이전과 동일하게 보험료를 납입하다가 본인 사후에 발생하는 사망 보험금을 기부하는 방법`,
    img: HowImg3,
    review: `"나보다 더 오래 남을 기관에 내가 가진 것의 일부라도 맡겨 어느 곳에서든 도움이 절실한 아이들을 돕기를 바라는 마음으로 보험기부를 결정했습니다."`,
    writer: `이서영 후원자`,
    link: `/`,
  },
];

const contactData = [
  {
    id: 1,
    tit: `문의`,
    costumer: `특별후원팀`,
    tel: `02-721-5155`,
    email: `major@unicef.or.kr`,
  },
];

const HeadDec = styled.p`
  ${breakpoint(`mobile`)} {
    br {
      display: none;
    }
  }
`;

const HowCard = styled.div`
  .card {
    border: 1px solid #b7b8ba;
    margin-bottom: 64px;
    padding: 64px;

    &:last-child {
      margin-bottom: 0;
    }

    .img-box {
      padding-top: 100%;
    }

    .card-top {
      .col-img {
        width: 80px;
        padding: 0;
      }

      .col-dec {
        width: calc(100% - 80px);
        padding-left: 32px;

        ${Tit} {
          display: block;
          margin-bottom: 16px;
          strong {
            color: #1cabe2;
          }
        }
      }
    }

    .card-btt {
      margin-top: 50px;
      background: #f8f9fa;
      padding: 48px 84px;
      position: relative;

      .col-img {
        width: 160px;
      }

      .col-dec {
        width: calc(100% - 160px);
        padding-left: 64px;

        .writer {
          margin-top: 24px;

          &::before {
            content: &lsquo;-&rsquo;;
            display: inline-block;
            margin-right: 3px;
          }
        }
      }

      ${ButtonMoreView} {
        width: 100%;
        max-width: 256px;
        position: absolute;
        right: 0;
        bottom: -28px;
      }
    }

    ${breakpoint(1160)} {
      padding: 5.52vw;

      .card-btt {
        padding: 4.14vw 7.24vw;
      }
    }

    ${breakpoint(`tablet`)} {
      padding: 48px 24px;

      .card-btt {
        margin-top: 32px;
        padding: 32px 16px;
        ${ButtonMoreView} {
          max-width: 148px;
        }
      }
    }

    ${breakpoint(`mobile`)} {
      .card-top {
        flex-wrap: wrap;
        justify-content: center;

        .col-dec {
          width: 100%;
          margin-top: 8px;
          padding: 0;

          ${Tit} {
            text-align: center;
          }
        }
      }
      .card-btt {
        flex-wrap: wrap;
        justify-content: center;

        .col-dec {
          width: 100%;
          margin-top: 32px;
          padding: 0;
        }
      }
    }
  }
`;

const SectionInfo = styled(Section)`
  ${SectionHeader} {
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${Button} {
      width: 352px;
    }
    ${breakpoint(`tablet`)} {
      margin-left: 0;
      margin-right: 0;
      h2 {
        padding: 0;
      }
      ${Button} {
        width: 100%;
        margin-top: 57px;
      }
    }
    ${breakpoint(`mobile`)} {
      display: block;
    }
  }
`;
const BnContainer = styled(Section)`
  img {
    width: 100%;
  }
`;

const MemorialCard = styled.div`
  border: 1px solid #b7b8ba;
  margin-top: 48px;
  padding: 40px;
  /* line-height: 1.2; */
  /* letter-spacing: 1.2px; */

  .title {
    font-size: 1.2rem;
    margin-bottom: 16px;
    font-weight: 600;
  }

  .description {
  }

  .donator {
    margin-top: 8px;
    text-align: right;
  }
`;

const SectionFaq = styled(Section)``;
const NavigationButton = styled.div`
  padding: 24px 48px 24px 24px;
  cursor: pointer;
  font-weight: bold;
  font-size: 20px;
  position: relative;

  ${breakpoint(640)} {
    font-size: 16px;
    padding: 20px 40px 20px 0;
  }
`;
const AccordionBody = styled.div`
  padding: 24px;
  font-size: 16px;
  color: #2d2926;
  ${breakpoint(640)} {
    font-size: 14px;
    padding: 16px 0;
  }
  // blut css 보정
  ul,
  ol,
  li {
    list-style: inherit;
    list-style-position: inside;
    list-style-type: inherit;
  }
  ol {
    list-style-type: demical;
  }
  ul {
    list-style-type: disc;
  }
  a {
    color: #1cabe2;
  }
`;

const SectionContact = styled(Section)`
  background: #f8f9fa;
  padding-bottom: 0 !important;

  ${Container} {
    padding-bottom: 120px;
  }
  .form-container {
    max-width: 736px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;

    ${FormGroup} {
      width: 50%;
      padding: 0 16px;
      margin-bottom: 24px;
      &.full {
        width: 100%;
      }
    }

    .chk-label {
      font-size: 14px;
    }

    ${breakpoint(`tablet`)} {
      ${FormGroup} {
        width: 100%;
        padding: 0;
      }
    }
  }

  .btn-wrap {
    margin-top: 78px;
    text-align: center;

    .btn {
      width: 100%;
      max-width: 350px;
    }
  }

  .clear {
    display: block;
    width: 100%;
    height: 0;
    clear: both;
    visibility: hidden;
  }

  .tip {
    background: #e2f0f6;
    line-height: 1.2;
    padding: 19px 0;
    text-align: center;
    font-size: 14px;

    p {
      max-width: 11600px;
      padding: 0 20px;
      margin: 0 auto;
      color: #1cabe2;
    }
  }
`;

export interface FaqData {
  id: string | number;
  title?: string;
  contents?: string;
}
interface PopupData {
  PopupSid?: string | number;
}

const Legacy: React.FC<PageProps> = ({ location }) => {
  const [faqLists, setFaqLists] = useState<FaqData[]>([]);

  const loadFaqLists = useCallback(async () => {
    try {
      const { data } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: ['260'],
        optData1: '1',
        optData2: '106',
        pagePerCount: 6,
        statusCode: '1',
      });
      const legacyFaq = data as any;
      setFaqLists(
        legacyFaq.map((faq: BoardVo) => ({
          id: faq.boardIndexNumber,
          title: faq.subject,
          contents: faq.contents,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadFaqLists();
  }, [loadFaqLists]);

  const [popupOpen, setPopupOpen] = useState(false);
  const [PopupTitle, setPopupTitle] = useState(``); // 업종1
  const [PopupSid, setPopupSid] = useState<PopupData | string | number>(``); // 업종1

  const open = () => setPopupOpen(true);
  const close = () => setPopupOpen(false);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<HonorsClubData>({
    defaultValues: {
      userName: ``,
      userMobile: ``,
      userEmail: ``,
      agreeCheck: false,
      formType: `honors`,
    },
  });
  const popupStore = usePopupStore();
  const onSubmit: SubmitHandler<HonorsClubData> = useCallback(
    async ({ userName, userMobile, userEmail, agreeCheck, ...formData }) => {
      try {
        const {
          resultCode,
          resultMessage,
        } = await HonorsClubControllerService.insertHonorsQnaUsingPost({
          ...formData,
          name: userName,
          mobile: userMobile,
          email: userEmail,
          isAgreeYn: agreeCheck ? `Y` : `N`,
          type: `legacy`,
        });
        popupStore.show(resultMessage);
        reset();
      } catch {
        popupStore.show(`문의 등록에 실패했습니다. 다시 시도해주세요.`);
      }
    },
    [popupStore],
  );

  const LegacyDonationRef = useRef();
  const MemorialDonationRef = useRef();

  const scrollToLegacyDonation = () => {
    LegacyDonationRef.current.scrollIntoView({ behavior: `smooth` });
  };
  const scrollToMemorialDonation = () => {
    MemorialDonationRef.current.scrollIntoView({ behavior: `smooth` }, []);
  };

  return (
    <LayoutWithTitle
      location={location}
      title="유산-추모기부"
      description="for every child, legacy giving"
    >
      <SectionTop
        className="by-sub-main-layout"
        css={`
          ${breakpoint(640)} {
            margin-bottom: 60px;
          }
        `}
      >
        <Container>
          <SectionHeader className="with-desc">
            <div className="flex-wrap">
              <h2>
                <Tit size="s1">
                  <PageComponent id="title1">
                    내 인생을 영원히 빛내줄 고귀한 약속
                  </PageComponent>
                </Tit>
              </h2>
              <div className="col-btn">
                <ul className="flex">
                  <li>
                    <Button full onClick={scrollToLegacyDonation}>
                      유산기부
                    </Button>
                  </li>
                  <li>
                    <Button full onClick={scrollToMemorialDonation}>
                      추모기부
                    </Button>
                  </li>
                </ul>
              </div>
            </div>
            <HeadDec className="head-dec">
              <PageComponent id="content1">
                나눔으로 기억되는 세상에서 가장 아름다운 기부,
                <br />전 세계 어린이의 더 좋은 세상, 더 나은 삶을 위해 함께해
                주세요
              </PageComponent>
            </HeadDec>
          </SectionHeader>
          <TabPanel tabs={tab} />
        </Container>
      </SectionTop>

      <Section className="by-sub-main-layout" ref={LegacyDonationRef}>
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">유산을 기부하는 방법</Tit>
            </h2>
          </SectionHeader>

          <HowCard>
            {howData.map((row) => (
              <div className="card">
                <div className="card-top flex">
                  <div className="col-img">
                    <div
                      className="img-box"
                      css={`
                        background-image: url(${row.obj});
                      `}
                    />
                  </div>

                  <dl className="col-dec">
                    <dt>
                      <Tit
                        size="s4"
                        dangerouslySetInnerHTML={{ __html: row.tit }}
                      />
                    </dt>
                    <dd>{row.dec}</dd>
                  </dl>
                </div>

                <div className="card-btt flex">
                  <div className="col-img">
                    <div
                      className="img-box"
                      css={`
                        background-image: url(${row.img});
                      `}
                    />
                  </div>

                  <div className="col-dec">
                    <Tit size="s4">{row.review}</Tit>
                    <p className="writer">{row.writer}</p>
                  </div>
                  <div>
                    <ButtonMoreView
                      mode="sky"
                      onClick={() => {
                        open();
                        setPopupTitle(row.tit);
                        setPopupSid(row.id);
                      }}
                    >
                      자세히 보기
                    </ButtonMoreView>
                  </div>
                </div>
              </div>
            ))}
            <LegacyLayerPopup
              isOpen={popupOpen}
              PopTitle={PopupTitle}
              PopSid={PopupSid}
              onRequestClose={close}
            />
          </HowCard>
        </Container>
      </Section>

      {/* <SectionInfo className="by-sub-main-layout">
        <Container>
          <SectionHeader
            className="with-desc"
            css={`
              ${breakpoint(640)} {
                display: block !important;
              }
            `}
          >
            <h2>
              <Tit size="s1">유산기부 안내자료</Tit>
            </h2>
            <div>
              <Button
                outline
                className="button"
                ico="down"
                onClick={() => {
                  open();
                  setPopupTitle(`유산기부 안내자료 다운로드`);
                  setPopupSid(4);
                }}
              >
                <span className="ico">다운로드</span>
              </Button>
            </div>
          </SectionHeader>
        </Container>
      </SectionInfo> */}

      <BnContainer
        ref={MemorialDonationRef}
        style={{ scrollMarginTop: '200px' }}
      >
        <Container>
          <GtmButtonDonate
            type="button"
            text="후원하기"
            color="white"
            className="btn-support-legacy"
            goto={`/donate/?supportTerm=FM02&supportCategory=D224&atype=09`}
            gtmInfo={{
              ...SelectItemDefaultData,
              itemId: 'D224',
              itemName: '기념기부',
              itemCategory: SUPPORT_TERM_ONCE,
            }}
            onClickPreEvent={() => {
              // TODO onClick before call gtm
              // alert('click');
            }}
          >
            <Image pcSrc={LegacyBn1} mobileSrc={LegacyBn1M} />
          </GtmButtonDonate>
        </Container>
      </BnContainer>

      <Container>
        <div
          style={{
            marginTop: '60px',
            // lineHeight: 1.2,
            // letterSpacing: '1.2px',
          }}
        >
          {/* <Tit size="s1">
                  <PageComponent id="title1">
                    내 인생을 영원히 빛내줄 고귀한 약속
                  </PageComponent>
                </Tit> */}
          <Tit
            size="s1"
            // style={{
            //   fontSize: '1.5rem',
            // }}
          >
            추모기부는 소중한 사람을
            <br />
            기억하는 아름다운 선택입니다
          </Tit>
          <div style={{ marginTop: '32px', marginBottom: '60px' }}>
            사랑하는 이를 떠나 보낸 후 우리는 함께한 날들을 추억하며 그리움에
            젖습니다.
            <br />
            다시 만날 수는 없지만 잊지 않고 기억할 수 는 있습니다.
            <br />
            함께 나눈 소중했던 시간들이 전 세계 어린이들의 삶 속에서 영원히 빛날
            수 있도록 추모하는 마음을 모아 유니세프에 보내주세요.
            <br />
            여러분이 보내주신 사랑은 어린이의 생명과 희망이 되어 우리의 세상을
            오래도록 환하게 밝힐 것입니다.
          </div>
          <TabPanel tabs={tab2} />
        </div>
        <MemorialCard>
          <div className="title">
            “하늘에 있는 아내와 딸을 대신해 기부합니다.”
          </div>
          <div className="description">
            “사랑하는 아내와 딸을 너무나 급작스럽게 잃었습니다. 주변 사람을 돕는
            일이라면 두 팔 걷어 붙이고 궂은 일을 도맡던 아내와 나중에 크면
            유니세프 같은 곳에서 일하고 싶다던 딸 태연이를 오래오래 기억하고
            싶었습니다. 유니세프를 통해 내 아내와 딸이 아프리카 어린이가 마실
            맑은 물을 선물했다고 생각합니다. 하늘에서 활짝 웃으며 기뻐할 아내와
            딸의 모습이 눈 앞에 선합니다.”
          </div>
          <div className="donator">
            - 매 년 아내와 딸의 기일에 1천만 원을 꾸준히 기부하고 있는 김융기
            후원자
          </div>
        </MemorialCard>

        <MemorialCard>
          <div className="title">
            “불우한 이웃과 어린이를 돕고 싶어하셨던 어머니를 대신해 기부합니다.”
          </div>
          <div className="description">
            “돌아가신 어머니를 대신하여 전쟁으로 고통받는 우크라이나 어린이를
            위해 기부합니다.”
          </div>
          <div className="donator">
            - 돌아가신 어머니를 추모하며 기부하신 이점진 후원자
          </div>
        </MemorialCard>
      </Container>

      <SectionFaq className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <div className="flex-middle">
              <h2>
                <Tit size="s1">자주 묻는 질문</Tit>
              </h2>
              <LinkSafe
                to="/faq?category=1&optData2=106#categorySection"
                className="more"
              >
                <span className="txt">더 보기</span>
              </LinkSafe>
            </div>
          </SectionHeader>
          {faqLists.length > 0 && (
            <AccordionComponent initialKey={faqLists[0].id}>
              {faqLists.map((faq) => (
                <AccordionItem
                  key={faq.id}
                  toggleKey={faq.id}
                  renderToggle={(onClick) => (
                    <NavigationButton
                      onClick={onClick}
                      className="accordion-opener"
                    >
                      {faq.title}
                    </NavigationButton>
                  )}
                >
                  <AccordionBody
                    dangerouslySetInnerHTML={{ __html: faq.contents }}
                  />
                </AccordionItem>
              ))}
            </AccordionComponent>
          )}
        </Container>
      </SectionFaq>

      <BnContainer className="by-sub-main-layout">
        <Container>
          <Image pcSrc={LegacyBn2} mobileSrc={LegacyBn2M} />
        </Container>
      </BnContainer>

      <SectionContact className="by-sub-main-layout">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Container>
            <SectionHeader className="with-desc">
              <h2>
                <Tit size="s1">유산기부 문의</Tit>
              </h2>
            </SectionHeader>

            <div className="form-container">
              <FormGroup>
                <LabelText require>성명</LabelText>
                <InputText
                  placeholder="성명 입력"
                  name="userName"
                  ref={register({
                    required: {
                      value: true,
                      message: `성명을 입력해주세요.`,
                    },
                    maxLength: {
                      value: 50,
                      message: `50자 이내로 입력해주세요.`,
                    },
                  })}
                />
                {errors.userName && (
                  <ErrorMessage>{errors.userName.message}</ErrorMessage>
                )}
              </FormGroup>
              <div className="clear" />
              <FormGroup>
                <LabelText require>연락처</LabelText>
                <Controller
                  control={control}
                  name="userMobile"
                  rules={{
                    required: {
                      value: true,
                      message: `연락처를 입력해주세요.`,
                    },
                    pattern: {
                      value: mobileRegex,
                      message: `올바른 연락처 형식이 아닙니다.`,
                    },
                    validate: {
                      isValidRange: (value) =>
                        (value.substr(0, 3) === `010` && value.length === 11) ||
                        (value.substr(0, 3) !== `010` && value.length === 10) ||
                        `휴대폰번호 자릿수를 확인해주세요`,
                    },
                  }}
                  as={
                    <InputNumber
                      format="###########"
                      placeholder="연락처 입력 ( &lsquo;-&rsquo; 제외 )"
                    />
                  }
                />
                {errors.userMobile && (
                  <ErrorMessage>{errors.userMobile.message}</ErrorMessage>
                )}
              </FormGroup>
              <FormGroup>
                <LabelText require>이메일</LabelText>
                <InputText
                  name="userEmail"
                  ref={register({
                    required: {
                      value: true,
                      message: `이메일을 입력해주세요.`,
                    },
                    pattern: {
                      value: emailRegex,
                      message: `올바른 이메일 형식이 아닙니다.`,
                    },
                    maxLength: {
                      value: 100,
                      message: `100자 이내로 입력해주세요.`,
                    },
                  })}
                  placeholder="이메일주소 입력"
                />
                {errors.userEmail && (
                  <ErrorMessage>{errors.userEmail.message}</ErrorMessage>
                )}
              </FormGroup>
              <FormGroup className="full">
                <InputCheckbox
                  className="chk-label"
                  name="agreeCheck"
                  label="예, 저는 유산기부 안내를 받고 싶습니다. 그리고 안내를 원치 않을 시, 언제든지 수신거부 할 수 있음을 잘 알고 있습니다."
                  ref={register({
                    required: {
                      value: true,
                      message: `약관 동의에 체크해주세요.`,
                    },
                  })}
                />
                {errors.agreeCheck && (
                  <ErrorMessage>{errors.agreeCheck.message}</ErrorMessage>
                )}
              </FormGroup>
            </div>
            <div className="btn-wrap">
              <div className="clear" />
              <Button className="btn" type="submit">
                문의하기
              </Button>
            </div>
          </Container>
        </form>
        <div className="tip">
          <p>
            법무법인 광장(LEE&#38;KO) 공익활동위원회의 &#39;유니세프
            법률지원팀&#39;이 유산기부에 필요한 법률자문을 제공합니다.
          </p>
        </div>
      </SectionContact>

      <Section className="by-sub-main-layout">
        <Container>
          <ContactUs itemData={contactData} />
        </Container>
      </Section>
    </LayoutWithTitle>
  );
};

export default Legacy;
